import { useEffect, useMemo, useState } from 'react'
import { classNames } from '../../../../helpers/classNames'
import { useTracking } from '../../../Shared/Hooks/TrackingHook'
import CarwowLogo from '../../../Shared/Images/CarwowLogo.svg'
import { asset } from '../../../Shared/asset'
import type { DropdownOption } from '../../Components/Select/Select'
import { Select } from '../../Components/Select/Select'
import { Arrow } from '../../Images/Arrow'
import { Check } from '../../Images/Check'
import { CheckMobile } from '../../Images/CheckMobile'
import data from './BrandsWithModelsData.json'
import CarwowCarDesktop from './Images/CarwowCarDesktop.png'
import CarwowCarMobile from './Images/CarwowCarMobile.png'
import CarwowStoererDesktop from './Images/CarwowStoererDesktop.png'
import CarwowStoererMobile from './Images/CarwowStoererMobile.png'

const modelOptionsDefault: DropdownOption[] = [
    {
        name: 'Bitte auswählen',
        value: '-1',
    },
]

export interface CarwowTeaserInterface {
    teaserDesignIndexSite: boolean
}

export default function CarwowTeaser({ teaserDesignIndexSite }: { teaserDesignIndexSite: boolean }) {
    const { trackingRef, trackClick } = useTracking({
        event_name: 'affiliate',
        event_data: {
            affiliate_type: 'carwow',
        },
    })

    const [isClicked, setIsClicked] = useState(false)
    const [brand, setBrand] = useState<string>('-1')
    const [model, setModel] = useState<string>('-1')
    const [modelOptions, setModelOptions] = useState(modelOptionsDefault)
    const brands = useMemo(() => {
        const sortedBrands = data.brandsWithModels.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base',
            }),
        )

        sortedBrands.forEach((brand) =>
            brand.models.sort((a, b) =>
                a.name.localeCompare(b.name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }),
            ),
        )

        return sortedBrands
    }, [])

    function clickHandler() {
        setIsClicked(true)

        const parameters = new URLSearchParams({
            affiliate: 'autobild',
            utm_medium: 'editorial',
            utm_source: 'affiliate',
            utm_campaign: 'af_de_springer-autobild',
            utm_content: 'Marken-Modell-Teaser',
        })

        const urlString = `
        https://www.carwow.de/angebote/${brand}/${model}?${parameters.toString()}`

        const url = new URL(urlString)
        url.searchParams.sort()

        trackClick({
            event_data: {
                affiliate_brand: brand,
            },
        })

        window.open(url.toString(), '_blank', 'noopener')
    }

    useEffect(() => {
        const filteredModelOptions = brands.filter((opt) => opt.value === brand)[0]

        setModelOptions(filteredModelOptions ? filteredModelOptions.models : modelOptionsDefault)

        setModel(filteredModelOptions ? filteredModelOptions.models[0].value : '-1')
    }, [brand, brands])

    return (
        <div
            className={classNames(
                !teaserDesignIndexSite && 'my-34 md:mx-teaser-xl-aubi md:-mt-9 md:mb-32',
                teaserDesignIndexSite && 'mb-40 mt-36 md:mt-41',
            )}
            ref={trackingRef}
        >
            {!teaserDesignIndexSite && (
                <span className="-mt-14 mb-14 flex w-full justify-end font-slab text-11 leading-12 text-tarmac-200 md:w-1010 md:leading-17">
                    ANZEIGE
                </span>
            )}

            {teaserDesignIndexSite && (
                <div className="mb-16 flex w-full items-end justify-between font-slab md:mb-15 md:w-1010">
                    <div
                        className="-mb-2 text-24 font-extrabold leading-[3.165rem] text-tarmac-500 md:mb-0 md:text-28 md:leading-36"
                        data-testid="separateHeadline"
                    >
                        Neuwagen kaufen
                    </div>
                    <span className="mb-5 text-11 leading-12 text-tarmac-200 md:-mb-2 md:leading-17">ANZEIGE</span>
                </div>
            )}

            <fieldset
                className={classNames(
                    'relative -mt-10 flex flex-col border-solid border-tarmac-75 font-slab',
                    !teaserDesignIndexSite && 'border-1 px-12 pb-12 pt-12 md:-mt-11 md:p-19',
                    teaserDesignIndexSite && 'border-t-1',
                )}
            >
                {!teaserDesignIndexSite && (
                    <legend className="absolute -top-15 left-13 -ml-7 bg-white px-6 text-18 font-bold md:left-16 md:px-10 md:uppercase">
                        Neuwagen kaufen
                    </legend>
                )}
                <div
                    className={classNames(
                        'bg-tarmac-25 px-12 pb-12 md:px-20 md:pb-20 md:pt-14',
                        !teaserDesignIndexSite && 'px-12 pb-12',
                        teaserDesignIndexSite && 'px-18 pb-18',
                    )}
                >
                    <div className="flex flex-col md:flex-row">
                        <div className="flex w-full flex-col items-end md:relative md:order-2 md:w-[32.1rem]">
                            <div
                                className={classNames(
                                    'relative h-auto w-full md:hidden',
                                    !teaserDesignIndexSite && 'mt-9',
                                    teaserDesignIndexSite && 'mt-18',
                                )}
                            >
                                <div
                                    className={classNames(
                                        'relative mx-auto block h-[16.5rem] max-w-[28.8rem] md:mt-0',
                                        !teaserDesignIndexSite && 'mb-6 mt-3 md:mb-0',
                                    )}
                                >
                                    <img
                                        className={classNames(
                                            'absolute h-auto w-[26.5rem]',
                                            teaserDesignIndexSite && '-bottom-4 -right-16',
                                            !teaserDesignIndexSite && '-bottom-7 -right-10',
                                        )}
                                        src={asset(CarwowCarMobile, { width: 265 })}
                                        alt="Carwow Pkw"
                                        loading="lazy"
                                        width="265"
                                        height="138"
                                    />
                                    <img
                                        className={classNames(
                                            'absolute top-0 h-auto w-[12rem]',
                                            teaserDesignIndexSite && '-left-6',
                                        )}
                                        src={asset(CarwowStoererMobile, { width: 120 })}
                                        alt="Spare im Durchschnitt 8.100€!*"
                                        loading="lazy"
                                        width="120"
                                        height="85"
                                    />
                                </div>
                                <div className="absolute -top-8 right-0 text-right">
                                    <span className="text-right font-slab text-12 font-normal text-tarmac-200">
                                        Ein Service von
                                    </span>
                                    <img
                                        className="ml-auto mr-0 mt-1 h-14 w-auto"
                                        src={asset(CarwowLogo, { width: 109 })}
                                        height="14"
                                        width="109"
                                        alt="Carwow Logo"
                                    />
                                </div>
                            </div>
                            <div
                                className={classNames(
                                    'z-50 hidden md:absolute md:top-8 md:flex md:h-[11.5rem] md:w-[16.3rem]',
                                    teaserDesignIndexSite && 'md:right-[20.2rem]',
                                    !teaserDesignIndexSite && 'md:right-[22rem]',
                                )}
                            >
                                <img
                                    className="hidden h-full w-auto md:block"
                                    src={asset(CarwowStoererDesktop, { width: 163 })}
                                    alt="Spare im Durchschnitt 8.100€!*"
                                    loading="lazy"
                                    width="163"
                                    height="115"
                                />
                            </div>
                            <div
                                className={classNames(
                                    'hidden h-auto w-full justify-end md:absolute md:bottom-0 md:flex',
                                    teaserDesignIndexSite && 'md:-mb-12 md:-mr-61',
                                    !teaserDesignIndexSite && 'md:-mb-11 md:-mr-25',
                                )}
                            >
                                <div
                                    className={classNames(
                                        'hidden md:absolute md:flex md:h-auto md:w-[20.8rem]',
                                        teaserDesignIndexSite && 'md:-top-24 md:right-10',
                                        !teaserDesignIndexSite && 'md:-top-28 md:right-14',
                                    )}
                                >
                                    <span className="mr-6 whitespace-nowrap text-right font-slab text-12 font-normal text-tarmac-200">
                                        Ein Service von
                                    </span>
                                    <img
                                        className="h-15 w-auto"
                                        src={asset(CarwowLogo, { width: 117 })}
                                        height="15"
                                        width="117"
                                        alt="Carwow Logo"
                                    />
                                </div>
                                <img
                                    className={classNames(
                                        'hidden md:block',
                                        !teaserDesignIndexSite && 'h-[16rem] w-[30.9rem] md:-ml-3',
                                        teaserDesignIndexSite && 'h-[16.5rem] w-[32.1rem]',
                                    )}
                                    src={asset(CarwowCarDesktop, { width: teaserDesignIndexSite ? 321 : 309 })}
                                    alt="Carwow Pkw"
                                    loading="lazy"
                                    width={teaserDesignIndexSite ? 321 : 309}
                                    height={teaserDesignIndexSite ? 165 : 160}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col md:order-1 md:w-[59.8rem]">
                            <div className="flex-row">
                                <p
                                    className={classNames(
                                        'mb-10 mt-0 w-full text-center text-22 font-extrabold leading-30 md:my-16 md:mb-0 md:mt-2 md:text-left md:text-24',
                                        teaserDesignIndexSite && 'mt-0',
                                        !teaserDesignIndexSite && 'mt-4',
                                    )}
                                >
                                    Einfach zum neuen Auto!
                                </p>

                                <p className="mb-5 hidden w-full md:mb-6 md:mt-3 md:block md:text-left md:text-22 md:leading-30">
                                    Finde günstige Kaufdeals.
                                </p>
                                <p className="mb-5 hidden text-center font-bold md:mb-5 md:block md:w-full md:text-left md:text-18 md:leading-30">
                                    Deine Carwow Vorteile:
                                </p>

                                <div className="mb-12 flex flex-col md:mb-5 md:flex-row md:justify-between">
                                    <div className="mb-7 flex flex-col leading-22 md:mb-0 md:h-68 md:w-[27.9rem] md:justify-between md:text-18 md:leading-26">
                                        <div className="-mb-1 flex w-full items-center">
                                            <div className="hidden md:block md:h-30 md:w-30">
                                                <Check />
                                            </div>

                                            <div className="block h-26 w-26 md:hidden">
                                                <CheckMobile />
                                            </div>

                                            <p className="ml-8 md:ml-10">Transparente Preise</p>
                                        </div>

                                        <div className="mb-1 mt-9 flex w-full items-center md:mb-0 md:mt-0">
                                            <div className="hidden md:block md:h-30 md:w-30">
                                                <Check />
                                            </div>

                                            <div className="block h-26 w-26 md:hidden">
                                                <CheckMobile />
                                            </div>

                                            <p className="ml-8 md:ml-10">Vertrauenswürdige Händler</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col md:h-70 md:w-[30.4rem] md:justify-between md:text-18 md:leading-26">
                                        <div className="-mb-1 flex w-full items-center md:mb-0">
                                            <div className="hidden md:block md:h-30 md:w-30">
                                                <Check />
                                            </div>

                                            <div className="block h-26 w-26 md:hidden">
                                                <CheckMobile />
                                            </div>

                                            <p className="ml-8 md:ml-10">Angebote aus deiner Nähe</p>
                                        </div>
                                        <div className="mt-9 flex w-full items-center md:mb-0 md:mt-0">
                                            <div className="hidden md:block md:h-30 md:w-30">
                                                <Check />
                                            </div>

                                            <div className="block h-26 w-26 md:hidden">
                                                <CheckMobile />
                                            </div>
                                            <p className="ml-8 md:ml-10">Stressfrei &amp; ohne Verhandeln</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-end md:flex-row">
                        <div
                            className={classNames(
                                'flex w-full flex-col items-end md:flex-row',
                                teaserDesignIndexSite && 'md:w-[65.212rem]',
                                !teaserDesignIndexSite && 'md:w-[61.212rem]',
                            )}
                        >
                            <div className="mb-11 w-full md:mb-0 md:w-1/2 md:pr-10">
                                <Select
                                    label="Marke"
                                    options={brands}
                                    value={brand}
                                    stateDispatcher={setBrand}
                                    placeholder="Bitte auswählen"
                                    data-testid="brandSelect"
                                />
                            </div>
                            <div className="mb-18 w-full md:mb-0 md:w-1/2 md:pl-10">
                                <Select
                                    label="Modell"
                                    options={modelOptions}
                                    value={model}
                                    stateDispatcher={setModel}
                                    disabled={brand === '-1'}
                                    data-testid="modelSelect"
                                />
                            </div>
                        </div>

                        <div className="w-full md:w-[31.788rem] md:pl-20">
                            <button
                                className={classNames(
                                    isClicked ? 'bg-red-500' : 'bg-tarmac-400',
                                    'flex h-[4.6rem] w-full cursor-pointer flex-row items-center justify-center border-0 border-solid p-10 text-14 font-bold uppercase text-white hover:bg-red-light-400 md:h-[5.7rem] md:text-18',
                                    !teaserDesignIndexSite && 'md:mb-0',
                                )}
                                onClick={clickHandler}
                                disabled={model === '-1' || brand === '-1'}
                                data-testid="ctaButton"
                            >
                                <span className="mr-9 leading-20 md:mr-12 md:leading-29">Angebote vergleichen</span>
                                <div className="h-20 w-10 text-14 md:h-29 md:w-15 md:text-16">
                                    <Arrow />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </fieldset>
            <div className="mt-[.85rem] flex w-full items-end justify-end text-tarmac-200 md:mt-6 md:w-1010">
                <span className="text-12 leading-18">
                    * Die durchschnittliche Ersparnis berechnet sich im Vergleich zur unverbindlichen Preisempfehlung
                    des Herstellers aus allen auf carwow errechneten Konfigurationen zwischen Juni und November 2023.
                    Sie ist ein Durchschnittswert aller angebotenen Modelle und variiert je nach Hersteller, Modell und
                    Händler.
                </span>
            </div>
        </div>
    )
}
